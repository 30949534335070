import React from "react";
import styled from "styled-components";
import { rgba } from "polished";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Text, Span } from "../../components/Core";
import { device } from "../../utils";



const Bookbutton = styled.button`
font-size: 16px;
padding: 12px 34px;
    background-color: #006b7b;
    display: block;
    color: #fff;
    border-radius: 32px;
    transition: .5s;
    border: none;
   

  @media screen and (max-width: 767px) {
    background-color: #006b7b;
    display: inline-block;
    padding: 12px 34px;
    font-size: 14px;
  }
`;



const Secondtext = styled.h2`

transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(
  0deg) rotateY(
  0deg) rotateZ(
  0deg) skew(
  0deg, 
  0deg);
      transform-style: preserve-3d;

      font-size: 2.2em;
      font-smooth: always;
      margin-bottom: 0.4em;
     
      color: #1a1a1a;
 
      font-weight: 100;
      letter-spacing: 2px;
      @media screen and (max-width: 767px)
     {
          margin-bottom: 24px;
          font-size: 18px;
       
      }

`;


const Separator = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #ffffff;

  ::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: #f7f7fb;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }
`;

const SeparatorEnd = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #f7f7fb;

  ::before, ::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
  }

  ::before {
    top: 0;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }

  ::after {
    bottom: 0;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }
`;

const AcneHeroPage = () => (
  <>
    {/* <!-- Content section 1 --> */}
  
  <Separator />
    <Section py={4} bg="#f7f7fb" className="">
      <Container className="pt-3 pb-3">

        <Row>

          <Col>
          <Text>
            We are on the lookout for both male and female models to try out our latest treatments. <br /> <br />

We can help you achieve the look you have always wanted. Don't miss this opportunity to pamper yourself and look your best - send us a message to book your slot today! <br /> <br />

Models are wanted for training purposes and/or social media/website content. <br /> <br />
               </Text>
          </Col>
        </Row>
      

        <Row className="align-items-center">
       
            <Col lg="12" className="order-lg-2">
                <Secondtext className="pb-2">How do I apply for a model treatment?</Secondtext>
            <Text>
            Simply send us an email to info@dermamina.com with the following: <br /> <br />

Subject: Model Treatment <br /> <br />
1. Full Name<br /> <br />
2. DOB<br /> <br />
3. Mobile number<br /> <br />
4. Consent for pictures to be posted?<br /> <br />
5. Treatment required<br /> <br />
6. Send us 3 clear pictures of your face<br /> 
(1 front and 2 from both sides)<br /> <br />
               </Text>

               <a href="mailto:info@dermamina.com" activeClassName="active">      
               <Bookbutton>
                Email Us
                
                </Bookbutton> </a>
            </Col>
           
           
        </Row>
   
      </Container>

    
     
    </Section>
    <SeparatorEnd />
    
  </>
);

export default AcneHeroPage;
